.SidebarNew {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 242px;
    height: 100%;
    background-color: #F1F2F7;
    border-right: 1px solid #E4E7EC;
    color: #475467;
    transition: width 0.5s;
    z-index: 100;
}

.sidebar-header {
    display: flex;
    align-items: center;
    padding: 10px 18px;
    height: 49px;
    background: #FFFFFF;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: #E4E7EC;
}

.sidebar-logo {
    font-size: 16px;
    margin: 0;
}

.sidebar-items {
    display: flex;
    flex-direction: column;
    height: 100%;

    .item {
        display: flex;
        align-items: center;
        padding: 10px 18px;
        min-height: 44px;

        &:hover {
            background-color: #DEE5F7;
            cursor: pointer;
        }
    }

    .item.active-item {
        background-color: #0152FB;

        &:hover {
            background-color: #0152FB;
            cursor: pointer;
        }
    }

    .dropdown-item {
        display: flex;
        align-items: center;
        padding: 10px 18px;
        min-height: 44px;
        cursor: pointer;

        .arrow-icon {
            margin-left: auto;
        }

        &:hover {
            background-color: #DEE5F7;
        }
    }

    .dropdown-item.active-dropdown {
        background-color: #0152FB;

        &:hover {
            background-color: #0152FB;
            cursor: pointer;
        }
    }

        .brands-sub-items {
    
            .sub-item {
                display: flex;
                align-items: center;
                padding: 8px 0 8px 49px;
                cursor: pointer;
    
                .sub-icon {
                    margin-right: 8px;
                }
    
                &:hover {
                    background-color: #DEE5F7;
                }
            }
    
            .sub-item.active-subitem {
                background-color: #DEE5F7;
    
                &:hover {
                    background-color: #DEE5F7;
                    cursor: pointer;
                }
            }
        }

    .item-brand {
        display: flex;
        align-items: center;
        padding: 10px 18px;
        animation: show-brand 0.6s;
    }
        @keyframes show-brand {
            0% {
                // transform: translateX(-100%);
                opacity: 0;
            }
    
            100% {
                // transform: translateX(0);
                opacity: 1;
            }
        }
}

.sidebar-icon {
    margin-right: 10px;
    cursor: pointer;
}

.subitem-popover {
    border: .75px solid #E4E7EC;
    border-radius: 2px;

    .popover-arrow::after {
        border-right-color: #FFFFFF;
    }

    .pop-body {
        background-color: #FFFFFF;
        padding: 0;

        .pop-items {
                .pop-item {
                    display: flex;
                    padding: 8px 16px;
                    cursor: pointer;
        
                    .itm-icon {
                        margin-right: 8px;
                    }
        
                    &:hover {
                        background-color: #F9FAFB;
                    }
                }
        
                .pop-item.active-popitem {
                    background-color: #DEE5F7;
        
                    &:hover {
                        background-color: #DEE5F7;
                        cursor: pointer;
                    }
                }
            }
    }
}

/*collapsed sidebar*/
.SidebarNew.collapsed {
    width: 60px;
}

.SidebarNew.collapsed .sidebar-header {
    justify-content: center;
}

.SidebarNew.collapsed .sidebar-icon {
    margin-right: 0;
}

.SidebarNew.collapsed .sidebar-text,
.SidebarNew.collapsed .sidebar-logo {
    display: none;
}

.SidebarNew.collapsed .sidebar-items .item {
    justify-content: center;
}
.sidebar-text {
    animation: text-show 1s;
}
@keyframes text-show {
    0% {
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.successmodal {
    .modal-dialog {
        .modal-content {
            .modal-header {
                .alert-icon {
                    background: #ECFDF3;
                    border: 8px solid #F6FEF9;
                }
            }

            .modal-body {
                .footer-btn {
                    padding: 32px 0 24px 0;
                }
            }

            .modal-footer {
            }
        }
    }
}
.addpartner {
  .row {
      .content-main {
          .parent-container {
              .breadcrumb-nav {
                  padding: 0 24px;
                  .breadcrumb {
                      margin-left: 0;
                      min-width: 100%;
                  }
              }
              .tabs-row {
                  padding: 0 24px;
              }
          }
      }
  }
}
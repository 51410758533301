.permission {
    background-color: #F9FAFB;
    min-height: 100vh;
    height: 100%;

    .permission-cntr {
        max-width: 680px;
        margin-left: auto;
        margin-right: auto;

        .breadcrumbnav {
            padding: 16px 0;
            text-transform: uppercase;

            .breadcrumb {
                padding: 0;
                margin: 0;

                .breadcrumb-item {
                    color: #667085;
                    font: normal normal 400 12px/18px "Inter", sans-serif;
                }

                .breadcrumb-item.active {
                    color: #1D2939;
                    font-weight: 500;
                }
            }
        }

        .form {
            background-color: #FFFFFF;
            box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
                0px 2px 4px -2px rgba(16, 24, 40, 0.06);
            border-radius: 4px 4px 0px 0px;

            .header-wraper {
                padding: 20px 0 16px 0;
                margin: 0 20px;
                border-bottom: 1px solid #E4E7EC;

                .form-header {
                    display: flex;
                    align-items: center;

                    .btn {
                        margin-right: 16px;
                    }
                }
            }

            .table-wraper {
                padding: 0 20px;

                table {
                    width: 100%;
                    border: 1px solid #E4E7EC;

                    tbody {
                        th {
                            width: 351px;
                            border: 1px solid #E4E7EC;
                            padding: 8px;
                            // padding-left: 8px;
                            font: normal normal 500 13px/19px "Inter", sans-serif;
                            color: #1D2939;

                            &:last-child {
                                text-align: center;
                                width: 289px;
                            }
                        }

                        .perm-td {
                            font: normal normal 500 13px/19px "Inter", sans-serif;
                            color: #1D2939;
                            border: 1px solid #E4E7EC;
                            padding: 8px;
                            text-align: center;
                        }

                        .module-td {
                            font: normal normal 500 13px/19px "Inter", sans-serif;
                            color: #1D2939;
                            border-right: 1px solid #E4E7EC;
                            padding-left: 16px;

                            .form-check {
                                input[type=checkbox] {
                                    margin-right: 12px;
                                }
                            }
                        }

                        .p-td {
                            border-right: 1px solid #E4E7EC;
                        }

                        .sub-module-tr {
                            .submodule-name-td {
                                font: normal normal 400 13px/19px "Inter", sans-serif;
                                color: #475467;
                                border-right: 1px solid #E4E7EC;
                                padding-left: 44px;

                                .form-check {
                                    input[type=checkbox] {
                                        margin-right: 8px;
                                    }
                                }
                            }
                        }

                        .perm-check {
                            font: normal normal 400 13px/19px "Inter", sans-serif;
                            border-right: 1px solid #E4E7EC;

                            .form-check {
                                display: flex;
                                justify-content: center;
                            }
                        }
                    }
                }

                .save-btn-wraper {
                    display: flex;
                    justify-content: flex-end;

                    .save-btn {
                        margin: 40px 0 20px 0;
                    }
                }
            }
        }
    }
}
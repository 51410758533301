.formButtonsContainer {
    padding: 3rem;
    display: flex;
    align-self: center;
    justify-content: flex-end;
  }
.formButton1,
  .formButton2 {
    margin: 0px 20px;
  }
  
.formButton1:hover {
    background-color: rgb(49, 49, 50);
  }
.formButton2:hover {
    background-color: rgb(31, 31, 211);
  }
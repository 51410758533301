.file-actions {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px 0 24px;
    border-top: 1px solid #E4E7EC;
    font: normal normal 400 14px/20px "Inter", sans-serif;

    .select-all {
        cursor: pointer;
    }

    .right-btns {
        display: flex;
        // justify-content: space-between;
        width: 100%;
        max-width: 180px;

        .btn {
            display: flex;
            padding: 0;
            background-color: #FFFFFF;
            font: normal normal 400 14px/20px "Inter", sans-serif;
            border: none;

            img {
                margin-right: 6px;
            }

            &:focus,
            &:hover {
                box-shadow: none;
                color: #475467;
            }

            &:last-child:hover {
                color: #D92D20;
            }

            &:disabled {
                color: #98A2B3;
            }
        }
        .info-btn {
            margin-right: auto;
        }
        .del-btn{ 
            margin-left: auto;
        }
    }
}
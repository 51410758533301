.font10-medium {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
}
.font10-regular {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
}
.font12-regular {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 18px;
}
.font13-regular {
  font: normal normal 400 13px/19px "Inter", sans-serif;
}
.font13-medium {
  font: normal normal 500 13px/19px "Inter", sans-serif;
}
.font14-regular {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
}
.font14-medium {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.font12-semibold {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
}
.font12-medium {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
.font16-regular {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.font16-medium {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.font18-medium {
  font: normal normal 500 18px/28px "Inter", sans-serif;
}
.font20-medium {
  font-size: 20px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}
.font20-semibold {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.font30-semibold {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
}
.white {
  color: #ffffff;
}
.gray-50 {
  color: "#F9FAFB"
}
.gray-200 {
  color: #E4E7EC;
}
.gray-400 {
  color: #98a2b3;
}
.gray-500 {
  color: #667085;
}
.gray-600 {
  color: #475467;
}
.gray-700 {
  color: #344054;
}
.gray-800 {
  color: #1d2939;
}
.gray-900 {
  color: #101828;
}
.tint-100 {
  color: #e6eef2;
}
.tint-900 {
  color: #1d6a8d;
}
.primaryPalette-900 {
  color: #120a05;
}
.primary-blue {
  color: #0152fb;
}
.error-500 {
  color: #F04438;
}
.error-600 {
  color:  #D92D20;
}
.error-700 {
  color: #B42318;
}
.warning-500 {
  color: #F79009;
}
.warning-700 {
  color: #B54708;
}
.blue-600 {
  color: #1570EF;
}
.blue-700 {
  color: #175CD3;
}
.success-600 {
  color: #039855;
}
.sidebar {
  flex-grow: 0;
  flex-basis: 60px;
}
.breadcrumb a {
  text-decoration: none;
  color: #667085;
}
.breadcrumb .breadcrumb-item.active {
  color: #1d2939;
}

/* input search start*/
.input-search {
  width: 180px;
  height: 36px;
  border-radius: 0;
  .input-group-text {
    background: #ffffff;
    border: 1px solid #e4e7ec;
    border-radius: 0;
  }
  .form-control {
    border-left: 0;
    border-radius: 0;
    border: 1px solid #e4e7ec;
  }
  .form-control:focus,
  .form-control:active {
    outline: none;
    box-shadow: none;
    border: 1px solid #e4e7ec;
    border-left: none;
  }
}
/* input search end*/
.input-email {
  .form-label {
    font: normal normal 500 14px/20px "Inter", sans-serif;
    color: #344054;
    margin-bottom: 6px;
  }

  .input-group {
    height: 40px;
    border: 1px solid #D0D5DD;

    .input-group-text {
      border-radius: 0;
      background: #FFFFFF;
      padding-right: 0;
      border-right: none;
      border: 0;
    }
    .icon-disabled-bg{
      background-color: #F9FAFB !important;
    }

    .form-control {
      border-radius: 0;
      border-left: none;
      border: 0;

      &:focus {
        box-shadow: none;
      }
      &:disabled {
        background-color: #F9FAFB;
    }
    }
  }
}
.input-password {
  .form-label {
    font: normal normal 500 14px/20px "Inter", sans-serif;
    color: #344054;
    margin-bottom: 6px;
  }
  .input-group {
    height: 40px;
    height: 100%;
    border: 1px solid #D0D5DD;
    .input-group-text {
      border-radius: 0;
      background: #FFFFFF;
      padding-left: 0;
      border-left: none;
      border: 0;
    }
    .form-control {
      border-radius: 0;
      border-right: none;
      border: 0;

      &:focus {
        box-shadow: none;
        // border: 1px solid #98A2B3;
      }
      &:disabled {
        background-color: #F9FAFB;
    }
    }
  }
}

/* filter-select start */
.filter-select {
  width: 98px;
  height: 36px;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  border: none;
  background-color: #f2f4f7;
  font-family: "Inter", sans-serif;
  padding: 9px 8px;
  border-radius: 0;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
/* filter-select end*/

/* button teritiary start*/
.btn-ter12 {
  height: 34px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
  background: #ffffff;
  border-radius: 0;
  border: 0;
  box-shadow: #ffffff;
  &:focus,
  &:hover {
    box-shadow: none !important;
    color: #667085;
    background-color: #ffffff;
  }
}

/* button teritiary end */

/* button btn-primary12 start */
.btn-primary12 {
  height: 38px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  background: #0152fb;
  border: 1px solid #0152fb;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 0;
}

.btn-primary12:focus,
.btn-primary12:hover {
  box-shadow: none !important;
}
.btnblue40 {
  height: 40px;
  font: normal normal 500 12px/18px "Inter", sans-serif;
  color: #ffffff;
  background: #0152fb;
  border: 1px solid #0152fb;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 0;
  &:focus, &:hover {
    box-shadow: none !important;
  }
}

.primary34-btn {
  height: 34px;
  width: 111px;
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: #ffffff;
  background: #344054;
  border: 1px solid #344054;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
.teritiary34-btn {
  height: 34px;
  background: #ffffff;
  color: #667085;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
}
.dark-btn:hover {
  background: #344054;
}
.btn-secondary12 {
  height: 38px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #344054;
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 0;
  &:focus,
  &:hover {
    background: #FFFFFF;
    color: #344054;
    box-shadow: none !important;
    border: 1px solid #D0D5DD;
  }
}
.link-btn {
  padding: 0;
  color: #0152FB;
  margin: 0;
  background-color: #FFFFFF;
  border: none;
  &:focus,&:hover {
    box-shadow: none !important;
    outline: none;
    color: #0152FB;
    background-color: #FFFFFF;
  }
}
.success-link-btn {
  padding: 0;
  color: #039855;
  &:focus,&:hover {
    box-shadow: none !important;
    outline: none;
    color: #039855;
  }
}
.error-link-btn {
  padding: 0;
  color: #D92D20;
  &:focus,&:hover {
    box-shadow: none !important;
    outline: none;
    color: #D92D20;
  }
}
.backarrow-btn{
  padding: 0;
  background-color: #F9FAFB;
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus,&:hover {
      box-shadow: none !important;
      outline: none;
      background-color: #F9FAFB;
  }
}
.trash-btn {
  background-color: #FFFFFF;
  padding: 0;
  border: none;
  &:focus, &:hover {
    box-shadow: none !important;
    background-color: #FFFFFF;
  }
}
.plus-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background: #F2F4F7;
  border: 1px solid #F2F4F7;
  &:focus, &:hover {
      box-shadow: none;
      background: #F2F4F7;
      border: 1px solid #F2F4F7;
      outline: none;
  }
}
.form-label {
  font: normal normal bold 12px/20px Inter;
  letter-spacing: 0px;
  color: #667085;
}

/* input search start*/
.input-search {
  width: 180px;
  height: 36px;
  border-radius: 0;
  .input-group-text {
    background: #ffffff;
    border: 1px solid #e4e7ec;
    border-radius: 0;
  }
  .form-control {
    border-left: 0;
    border-radius: 0;
  }
  .form-control:focus,
  .form-control:active {
    outline: none;
    box-shadow: none;
    border: 1px solid #e4e7ec;
    border-left: none;
  }
}
/* input search end*/

/* filter-select start */
.filter-select {
  width: 98px;
  height: 36px;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  border: none;
  background-color: #f2f4f7;
  font-family: "Inter", sans-serif;
  padding: 9px 8px;
  border-radius: 0;
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
/* filter-select end*/

/* button teritiary start*/
.btn-ter12 {
  height: 34px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #667085;
  background: #ffffff;
  border-radius: 0;
  border: 0;
  box-shadow: #ffffff;
  &:focus,
  &:hover {
    box-shadow: none !important;
    color: #667085;
    background-color: #ffffff;
  }
}

/* Table Container Dimensions*/
.TableMUIcountainer {
  height: 79vh;
  width: 100%;
}

/* Tabs style starts */
.tabs-global {
  border-bottom: 1px solid #e4e7ec;
  background: #ffffff;
  .nav-item {
    flex: 0 0 auto;
  }
  .nav-item button {
    width: auto;
  }
  .nav-link {
    color: #475467;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
  }
  .nav-link.active {
    border-color: #fff;
    border-bottom: 2px solid #12b76a !important;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #1d2939;
  }
  .nav-link {
    &:focus,
    &:hover {
      border-color: #fff;
    }
  }
}
/* Tabs style ends */

/* Form group */
.formgroup {
  .form-label {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin-bottom: 6px;
  }
  .form-control, .form-select, .input-group-text {
    height: 40px;
    border-radius: 0;
    border: 1px solid #D0D5DD;
  }
  .form-control, .form-select:focus,
  .form-control, .form-select:active {
    outline: none;
    box-shadow: none;
    border: 1px solid #D0D5DD;
  }
}
/* end */

/* Margins */
.mr6px {
  margin-right: 6px;
}
.mr8px {
  margin-right: 8px;
}
.mr10px {
  margin-right: 10px;
}
.mr12px {
  margin-right: 12px;
}
.mr16px {
  margin-right: 16px;
}
.mr28px {
  margin-right: 28px;
}
.mr48px {
  margin-right: 48px;
}
.mb6px {
  margin-bottom: 6px;
}
.mb12px {
  margin-bottom: 12px;
}
.mb16px {
  margin-bottom: 16px;
}
.mb20px {
  margin-bottom: 20px;
}
.mb32px {
  margin-bottom: 32px;
}
.mb40px {
  margin-bottom: 40px;
}
.mb24px {
  margin-bottom: 24px;
}
.mt4px {
  margin-top: 4px;
}
.mt8px {
  margin-top: 8px;
}
.mt16px {
  margin-top: 16px;
}
.mt20px {
  margin-top: 20px;
}
.mt40px {
  margin-top: 40px;
}
.mt24px {
  margin-top: 24px;
}
.mt29px {
  margin-top: 29px;
}
.mt-32px {
  margin-top: 32px;
}
.mt34px {
  margin-top: 32px;
}
.mt56px {
  margin-top: 56px;
}
/* end */

/* padding */
.pr8px {
  padding-right: 8px;
}
.pl8px {
  padding-left: 8px;
}
.plr20px {
  padding: 0 20px;
}
.pl20px {
  padding-left: 20px;
}
.pr20px {
  padding-right: 20px;
}
.pt24px {
  padding-top: 24px;
}
/* end */

/* Border */
.borderbottom {
  border-bottom: 1px solid #D0D5DD;
}
.bt-gray200 {
  border-top: 1px solid #E4E7EC;
}
.br-gray200 {
  border-right: 1px solid #E4E7EC;
}
.bl-gray200 {
  border-left: 1px solid #E4E7EC;
}
.b-gray200 {
  border: 1px solid #E4E7EC;
}
/* end */

.display-result {
  border: 1px solid #D0D5DD;
  padding: 12px;
  margin: 0;
  .input-display {
      border: 1px solid #D0D5DD;
      height: 40px;
      padding: 6px;
      overflow: auto;
      word-break: break-word;
  }
}

/* Modal layout */
.modal-layout {
  .modal-content {
    border-radius: 0;
    .modal-header {
      .btn-close {
        &:focus,
        &:hover {
          box-shadow: none !important;
        }
      }
    }
  }
}
/* end */

.asynselect {
  .css-1s2u09g-control {
    border-radius: 0;
    height: 40px;
  }
}
.success-status {
  background-color: #D1FADF;
  color: #039855;
  padding: 2px 8px;
  // font: normal normal 500 10px/18px "Inter", sans-serif;
}
.blue-status {
  background-color: #D1E9FF;
  color: #1570EF;
  padding: 2px 8px;
}
.warning-status {
  background-color: #FEF0C7;
  color: #DC6803;
  padding: 2px 8px;
}
.curserpointer {
  cursor: pointer;
}
.badgepill-blue50 {
  background-color: #EFF8FF;
  border-radius: 30px;
  padding: 4px 12px;
}
.badgepill-gray50 {
  background-color: #F9FAFB;
  border-radius: 34px;
  border: 1px solid #F2F4F7;
  padding: 1px 8px;
}
.checkbox16 {
  input[type=checkbox] {
    width: 16px;
    height: 16px;
    border-radius: 3px;

    &:focus {
      box-shadow: none;
    }
    &:disabled {
      background-color: #F2F4F7;
      border: 1px solid #EAECF0;  
    }
  }
}

/*Multi select custom style*/
#css_custom {
  .search-wrapper{ 
      border: 1px solid #D0D5DD;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 0;
      .chip {
          font: normal normal 400 12px/18px "Inter", sans-serif;
          padding: 2px 8px;
          background-color: #F2F4F7;
          color: #667085;
          border-radius: 3px;
      }
  }
  .optionListContainer {
      .optionContainer {
          .option {
              color: #1d2939;
              &:hover {
                  background-color: #F9FAFB;
              }
          }
          .highlightOption {
              background-color: #F9FAFB;
          }
      }
  }
}
/*Multi select custom style end*/
.pge-layout {
  .row {
    .content-section {
      padding: 0;
      .action-panel {
        padding: 16px 24px;
        display: flex;

        .adduser {
          margin-left: auto;
        }
        .addbtn {
          margin-left: auto;
        }

        .addfilter {
          margin-left: 20px;
        }
      }

      .table-title {
        padding: 0 24px;
      }

      .underline-gray200 {
        padding: 0 24px;

        div {
          border: 1px solid #E4E7EC;
          width: 100%;
          margin-top: 8px;
          margin-bottom: 16px;
        }
      }

      .table-mui {
        padding: 0 24px;
        margin-bottom: 16px;
      }
      .tabs-wraper {
        padding: 12px 24px 0 24px;
      }
    }
  }
}
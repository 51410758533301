.uploadedfile-cntr {
    width: 96px;
    color: #1D2939;
    margin-right: 20px;

    .filehead {
        border: 1px solid #E4E7EC;
        border-radius: 4px;
        font: normal normal 400 10px/18px "Inter", sans-serif;

        .img-wraper {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #F9FAFB;
            height: 69px;
            position: relative;

            .close-btn {
                position: absolute;
                top: -7px;
                right: -7px;
                display: inline-flex;
                padding: 0;
                background-color: #FFFFFF;
                border: 0;

                &:focus,
                &:hover {
                    box-shadow: none !important;
                }
            }
        }

        .note {
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    .file-name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font: normal normal 400 13px/19px "Inter", sans-serif;
    }
}
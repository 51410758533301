.settingslist {
  .row {
    .settings-content {
      .breadcrumb-nav {
        padding: 0 24px;

        .breadcrumb {
          margin: 0;
          padding-top: 16px;
        }
      }

      .usermanage-accordion,
      .advisory-accordion,
      .document-accordion,
      .platform-accordion {
        a {
          text-decoration: none;
          margin-left: 11px;
        }

      }

      .accordion-button:not(.collapsed) {
        background-color: #FFFF;
        color: #1D2939;
      }

      .usermanage-accordion {
        padding: 32px 24px 12px 24px;
      }

      .advisory-accordion,
      .document-accordion,
      .platform-accordion {
        padding: 12px 24px 12px 24px;
      }
    }
  }
}
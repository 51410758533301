.header-1 {
  padding: 0 24px;
  height: 49px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4e7ec;
  .icons {
    margin-left: auto;
    .notification-bell {
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}
.breadcrumb .breadcrumbItem {
  font-family: "Inter", sans-serif;
  min-width: 102px;
  height: 18px;
  border: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #1d2939;

  /* Inside auto layout */
  flex: none;
  order: 4;
  flex-grow: 0;
}

.borderbottom {
  border-bottom: 1px solid #e4e7ec;
}

.title {
  min-width: 135px;
  height: 28px;
  margin-left: 2rem;
  padding-top: 1rem;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #a4562d;
}

.columnflex {
  display: flex;
  flex-direction: column;
}

.formitemscontainer {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-around;
  padding-top: 0.25rem;
  margin: 1rem;
  width: 75%;
}

/* Organization Management CSS*/

.breadcrumb {
  margin-left: 8px;
  min-width: 500px;
  width: 100%;
  margin-right: auto;
  font-size: 0.75rem;
  font-weight: 600;
  padding-top: 10px;
}

.column1 {
  display: flex;
  flex-grow: 1;
  width: 90%;
  padding: 10px 20px;
}
.inputSearch {
  width: 180px;
  height: 36px;
}
.inputGroupText {
  background: #ffffff;
  border: 1px solid #e4e7ec;
}
.formControl {
  border-left: 0;
}
.formControl:focus,
.formControl:active {
  outline: none;
  box-shadow: none;
  border: 1px solid #e4e7ec;
  border-left: none;
}
.filterSelect {
  width: 98px;
  height: 36px;
  margin-left: 20px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  border: none;
  background-color: #f2f4f7;
  font-family: "Inter", sans-serif;
  padding: 9px 8px;
}
.filterSelect:focus {
  outline: none;
  box-shadow: none;
}
.teritiary34btn {
  margin-left: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.adduserBtn {
  margin-left: auto;
}

.buttonHover:hover {
  background-color: rgb(20, 6, 223);
}

.column2 {
  margin: 10px 40px 10px 10px;
  align-self: center;
  flex-grow: 0;
}
.colfilterBtn {
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 6px;
  height: 36px;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 30px;
  padding: 30px 10px;
  color: #1d2939;
}

.content1 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
}

.content4 {
  min-width: 500px;
  width: 98%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 2.5rem;
  min-height: 100vh !important;
  z-index: 100;
}

.containerForm {
  min-height: 60vh;
  border-bottom: 1px solid #838689;
}

.formButtonsContainer {
  padding: 3rem;
  display: flex;
  align-self: center;
  justify-content: flex-end;
}

.formButton1,
.formButton2 {
  margin: 0px 20px;
}

.formButton1:hover {
  background-color: rgb(49, 49, 50);
}

.formButton2:hover {
  background-color: rgb(31, 31, 211);
}

.form-mrkt-presense {
  margin-top: 40px;
  .row-add-mrkt {
    border: 1px solid #e4e7ec;
    .col-input {
      border-right: 1px solid #e4e7ec;
      padding: 16px;
    }
  }
  .display-result {
    border: 0;
    padding: 0;
    .row {
      border: 1px solid #e4e7ec;
    }
    .col-country {
      border-right: 1px solid #e4e7ec;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .col-services {
      padding: 12px;
      border-right: 1px solid #e4e7ec;
      .label-service {
        background-color: #f1f2f7;
        display: inline-block;
        padding: 4px;
      }
    }
    .col-actions {
      .iconsHolder {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;
      }
    }
  }
  .footer-container {
    margin-top: 40px;
    border-top: 1px solid #e4e7ec;
  }

  .input-box-row {
    .input-box {
      width: 30vw;
      .focus-category {
        width: 31vw;
      }
    }
  }
}

.admin-dashboard {
  height: 100vh;
  background: #f5f5f5;
  .row {
    .col-content {
      background: #f5f5f5;
      .details-section {
        padding-top: 12px;

        .row-details-section {
          padding: 0 24px;
          .col-todolist {
            padding: 0;
            .todo-header {
              margin-right: 6px;
              overflow: auto;
              border-radius: 6px 6px 0 0;
              height: 64px;
              border: 1px solid #e4e7ec;
              border-bottom: none !important;
              background: #ffffff;
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #1d2939;
              display: flex;
              align-items: center;
              padding-left: 16px;
            }
            .todo {
              margin-right: 6px;
              height: 64vh;
              overflow: auto;
              border: 1px solid #e4e7ec;
              border-top: none !important;
              border-radius: 0 0 6px 6px;
              background: #ffffff;
              &::-webkit-scrollbar {
                width: 8px;
                border-radius: 8px;
              }
              &::-webkit-scrollbar-thumb {
                background-color: #eaecf0;
                border-radius: 8px;
              }
            }
          }
          .col-feedresouce {
            padding: 0;
            .cont-feedresouce {
              .row {
                .col-feed {
                  padding: 0;
                  .feedtitle {
                    margin-left: 6px;
                    padding-left: 16px;
                    padding-right: 16px;
                    height: 64px;
                    background: #ffffff;
                    border: 1px solid #e4e7ec;
                    border-bottom: none !important;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .refresh-btn {
                      background: #FFFFFF;
                      border: 0;
                      padding: 0;
                      &:focus,
                      &:hover {
                        background: #FFFFFF;
                        box-shadow: none !important;
                      }
                    }
                  }
                  .feed {
                    height: 28vh;
                    overflow: auto;
                    border: 1px solid #e4e7ec;
                    border-top: none !important;
                    background: #ffffff;
                    margin-left: 6px;
                    border-radius: 0 0 6px 6px;
                    &::-webkit-scrollbar {
                      width: 8px;
                      border-radius: 8px;
                    }
                    &::-webkit-scrollbar-thumb {
                      background-color: #eaecf0;
                      border-radius: 8px;
                    }
                  }
                }
                .col-resouce {
                  padding: 0;
                  margin-top: 14px;
                  .rsc-title {
                    margin-left: 6px;
                    padding-left: 16px;
                    height: 64px;
                    background: #ffffff;
                    border: 1px solid #e4e7ec;
                    border-bottom: none !important;
                    display: flex;
                    align-items: center;
                  }
                  .resource {
                    height: calc(64vh - (36vh + 14px));
                    overflow: auto;
                    border: 1px solid #e4e7ec;
                    border-top: none !important;
                    background: #ffffff;
                    margin-left: 6px;
                    border-radius: 0 0 6px 6px;
                    &::-webkit-scrollbar {
                      width: 8px;
                      border-radius: 8px;
                    }
                    &::-webkit-scrollbar-thumb {
                      background-color: #eaecf0;
                      border-radius: 8px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

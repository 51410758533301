.rolepermissions-modal {
    .modal-dialog {
        max-width: 680px;

        .modal-content {
            border-radius: 0;

            .modal-header {
                border: 0;
                padding: 18px 20px;
                border-bottom: 1px solid #E4E7EC;

                .btn-close {

                    &:focus,
                    &:hover {
                        box-shadow: none !important;
                    }
                }
            }

            .modal-body {
                padding: 20px !important;

                .table-wraper {

                    table {
                        width: 100%;
                        border: 1px solid #E4E7EC;

                        tbody {
                            th {
                                width: 351px;
                                border: 1px solid #E4E7EC;
                                padding: 8px;
                                font: normal normal 500 13px/19px "Inter", sans-serif;
                                color: #1D2939;

                                &:last-child {
                                    text-align: center;
                                    width: 289px;
                                }
                            }

                            .perm-td {
                                font: normal normal 500 13px/19px "Inter", sans-serif;
                                color: #1D2939;
                                border: 1px solid #E4E7EC;
                                padding: 8px;
                                text-align: center;
                            }

                            .module-td {
                                font: normal normal 500 13px/19px "Inter", sans-serif;
                                color: #1D2939;
                                border-right: 1px solid #E4E7EC;
                                padding-left: 16px;

                                .form-check {
                                    input[type=checkbox] {
                                        margin-right: 12px;
                                    }
                                }
                            }

                            .p-td {
                                border-right: 1px solid #E4E7EC;
                            }

                            .sub-module-tr {
                                .submodule-name-td {
                                    font: normal normal 400 13px/19px "Inter", sans-serif;
                                    color: #475467;
                                    border-right: 1px solid #E4E7EC;
                                    padding-left: 44px;

                                    .form-check {
                                        input[type=checkbox] {
                                            margin-right: 8px;
                                        }
                                    }
                                }
                            }

                            .perm-check {
                                font: normal normal 400 13px/19px "Inter", sans-serif;
                                border-right: 1px solid #E4E7EC;

                                .form-check {
                                    display: flex;
                                    justify-content: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}
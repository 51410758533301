.SettingFileLIst {
  .row {
    .alert-wraper {
      max-width: 834px;
      padding: 16px 16px 0 24px;
    }

    .folder-wraper {
      padding: 16px 24px;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.AlertMessage {
    background-color: #EFF8FF;
    border: 1px solid #1570EF;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 0;
    .btn-close {
      display: none;
    }
  
    .btn-close {
  
      &:focus,
      &:hover {
        box-shadow: none !important;
      }
    }
  
    .alert-heading {
      margin-bottom: 10px;
  
      div {
        display: flex;
        align-items: center;
  
        .icon-img {
          margin-right: 8px;
        }
        .close-btn {
          margin-left: auto;
          cursor: pointer;
        }
      }
    }
  
    p {
      margin-bottom: 0;
      padding: 0 28px;
    }
  }
  .AlertMessage.warning {
    background-color: #FFFAEB;
    border: 1px solid #DC6803;
  }
.url-list {
    table {
        tbody {
            tr {
                td {
                    padding: 8px 12px 8px 0;

                    .form-check {
                        .form-check-input {
                            height: 16px;
                            width: 16px;
                            border: 1px solid #D0D5DD;

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }

                    .form-select {
                        min-width: 128px;
                    }

                    .urlnote-wraper {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: 40px;
                        width: 748px;
                        background-color: #F9FAFB;
                        border: 1px solid #D0D5DD;

                        .url-tag {
                            padding: 10px 0 10px 14px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;

                            a {
                                color: #667085;
                                text-decoration: none;
                            }
                        }

                        .url-note {
                            .popover {
                                border: 1px solid #E4E7EC;
                                border-radius: 2px;
                                max-width: 396px;
                                width: 100%;
                                box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

                                .popover-header {
                                    display: flex;
                                    font: normal normal 500 14px/20px "Inter", sans-serif;
                                    color: #1D2939;
                                    justify-content: space-between;
                                    padding: 14px;
                                    border-bottom: none;
                                    background: #FFFFFF;

                                    img {
                                        cursor: pointer;
                                    }
                                }

                                .popover-body {
                                    padding: 0 14px 14px 14px;

                                    .form-control {
                                        height: 107px;
                                        background: #FFFFFF;
                                        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                                        border: 1px solid #D0D5DD;
                                        border-radius: 4px;
                                    }
                                }
                            }

                            .note-btn {
                                background-color: #F9FAFB;
                                border-radius: 0;
                                border: none;

                                &:focus {
                                    box-shadow: none;
                                }
                            }
                        }
                    }

                    .form-control,
                    .form-select:focus,
                    .form-control,
                    .form-select:active {
                        outline: none;
                        box-shadow: none;
                        border: 1px solid #D0D5DD;
                    }

                    .form-control,
                    .form-select {
                        height: 40px;
                        border-radius: 0;
                        border: 1px solid #D0D5DD;
                        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    }

                }
            }
        }
    }
}
.statusBadge {
  margin: 1rem;
  padding: 0.5rem;
  background-color: #d1e9ff;
  color: #0152fb;
}

.detailsDisplayModal {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.detailsModalBody {
  background-color: #f8f6f6;
}

.detailsRow {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  min-height: 2.5rem;
}

.socialMediaLinkIcon {
  cursor: pointer;
}

.detailsTextContent {
  color: #1d2939;
  margin: auto;
}

.boldDetailsTitle {
  margin: auto;
  font-weight: 650;
}

.preferencesListBox {
  border: 1px solid #e4e7ec;
  margin: 0.5rem;
  width: fit-content;
  .preferencesListItem {
    margin: auto;
    color: #1d2939;
    font-size: 13px;
  }
}

.detailsBoxDocumentsList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .documentsListName {
    margin: auto;
    margin-left: 7px !important;
    color: #1d2939;
  }
}

.upload-cntr {
    // margin: 32px 0 0 0;
    .drag-drop-row {
        // max-width: 801px;
        border: 1px solid #E4E7EC;
        border-style: dashed;
        background-color: #FCFCFD;
        .drag-drop-col {
            padding: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 16px;
            cursor: pointer;
        }
    }
    // .list-images-row {
    //     max-width: 801px;
    //     border: 1px solid #E4E7EC;
    //     border-top: 0;
    //     padding: 17px 0 32px 16px;
    //     overflow-x: scroll;
    //     flex-wrap: nowrap;
    //     justify-content: flex-start;    
    //     .list-images-col {
    //         padding: 0;
    //         position: relative;
    //         .close-btn {
    //             position: absolute;
    //             top: -10px;
    //             left: 99px;
    //             display: inline-flex;
    //             padding: 0;
    //             background-color: #FFFFFF;
    //             border: 0;
    //             &:focus,
    //             &:hover {
    //                 box-shadow: none !important;
    //             }
    //         }
    //     }
    // }
    // .upload-btn-row {
    //     max-width: 801px;
    //     .upload-btn-col {
    //         padding: 0;
    //         display: flex;
    //         justify-content: flex-end;
    //         margin-top: 16px;
    //     }
    // }
    // .uploads-heading-row {
    //     max-width: 801px;
    //     padding: 10px 0;
    //     border-bottom: 1px solid #D0D5DD;
    //     margin-bottom: 16px;
    // }
    // .uploadedimages-row {
    //     max-width: 801px;
    //     border: 1px solid #E4E7EC;
    //     padding: 17px 0 32px 16px;
    //     overflow-x: scroll;
    //     flex-wrap: nowrap;
    //     justify-content: flex-start;    
    //     .uploadedimages-col {
    //         padding: 0;
    //         position: relative;
    //         .close-btn {
    //             position: absolute;
    //             top: -10px;
    //             left: 99px;
    //             display: inline-flex;
    //             padding: 0;
    //             background-color: #FFFFFF;
    //             border: 0;
    //             &:focus,
    //             &:hover {
    //                 box-shadow: none !important;
    //             }
    //         }
    //     }
    // }
    // .savecancelbtn-row {
    //     position: fixed;
    //     bottom: 0;
    //     right: 24px;
    // }
}
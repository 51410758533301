.url-selector-wraper {
    .url-selector {
        display: flex;
        margin-bottom: 16px;
        font: normal normal 400 13px/19px "Inter", sans-serif;

        .form-select {
            width: 135px;
            margin-right: 12px;
        }

        .form-control {
            background-color: #F9FAFB;
        }

        .form-control,
        .form-select:focus,
        .form-control,
        .form-select:active {
            outline: none;
            box-shadow: none;
            border: 1px solid #D0D5DD;
        }

        .form-control,
        .form-select {
            height: 40px;
            border-radius: 0;
            border: 1px solid #D0D5DD;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        }
        .url-textbox {
            border-right: none;
        }

        .note-btn {
            background-color: #F9FAFB;
            border-radius: 0;
            height: 40px;
            border-left: none;
            border-top: 1px solid #D0D5DD;
            border-right: 1px solid #D0D5DD;
            border-bottom: 1px solid #D0D5DD;

            &:focus {
                box-shadow: none;
            }
        }

        .popover {
            border: 1px solid #E4E7EC;
            border-radius: 2px;
            max-width: 396px;
            width: 100%;
            box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

            .popover-header {
                display: flex;
                font: normal normal 500 14px/20px "Inter", sans-serif;
                color: #1D2939;
                justify-content: space-between;
                padding: 14px;
                border-bottom: none;
                background: #FFFFFF;

                img {
                    cursor: pointer;
                }
            }

            .popover-body {
                padding: 0 14px 14px 14px;

                .form-control {
                    height: 107px;
                    background: #FFFFFF;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    border: 1px solid #D0D5DD;
                    border-radius: 4px;
                    &:focus {
                        border: 1px solid #98a2b3;
                    }
                }
            }
        }
    }
}
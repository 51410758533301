.toast-cntnr {
    padding: 0;
    border: 1px solid #A6F4C5;
    position: fixed !important;
    bottom: 16px !important;
    opacity: 1;
    transform: translateY(0);
    // transition: 0.5s ease;
    transition: bottom 0.5s ease, opacity 0.5s ease;

    .toast {
        background-color: #ECFDF3;
        border-radius: 0;
        border: 0;
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);

        .toast-body {
            display: flex;
            align-items: center;
            padding: 10px 20px;
            text-transform: capitalize;

            img {
                margin-right: 8px;
            }
        }
    }
}
.toast-cntnr.error-border {
    border: 1px solid #FECDCA;
}
.toast-cntnr.warning-border {
    border: 1px solid #FEDF89;
}
.toast-cntnr .toast.errorbg {
    background-color: #FEF3F2;
}
.toast-cntnr .toast.warningbg {
    background-color: #FFFAEB;
}
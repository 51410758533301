.form-digi-presns{
    margin-top: 40px;
    .container-1 {
        min-height: 60vh;
    }
    .footer-container {
        margin-top: 40px;
        border-top: 1px solid #E4E7EC;
    }
}
.mr-top {
    margin-top: 16px;
}

.empty-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .illustration {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            margin-bottom: 16px;
        }
    }
}
.advisoryreportlist {
    .row {
      .report-content {
        .breadcrumb-nav {
          padding: 0 24px;
          .breadcrumb {
            margin: 0;
            padding-top: 16px;
          }
        }
        .action-panel {
            padding: 16px 24px;
            display: flex;
            .add-btn {
              margin-left: auto;
            }
            // .addfilter {
            //   margin-left: 20px;
            // }
          }
          .advisoryreporttable {
            padding: 0 24px;
          }
      }
    }
  }
  
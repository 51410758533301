.headerBar {
  height: 100%;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.headerPlusIcon {
  margin-right: 10px;
}

.navItem {
  flex: 0 0 auto;
}
.navItem button {
  width: auto;
}
.navLinkActive {
  border-color: #fff;
  border-bottom: 2px solid #367a99 !important;
}
.navLink:focus,
.navLink:hover {
  border-color: #fff;
}

.column1 {
  display: flex;
  flex-grow: 1;
  width: 90%;
  padding: 10px 20px;
}

.column2 {
  margin: 10px 40px 10px 10px;
  align-self: center;
  flex-grow: 0;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 30px;
  padding: 10px;
  color: #1d2939;
}

.content1 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
}
.content4 {
  min-width: 500px;
  width: 98%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 2.5rem;
  min-height: 100vh !important;
  z-index: 100;
  overflow: hidden;
}

.filelist-cntr {
  .list-cntr {
    display: flex;
    flex-wrap: wrap;
    padding: 29px 24px 40px 24px;
  }

  .url-table-cntr {
    margin: 40px 24px;
  }
}

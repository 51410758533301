.btn-add-sku {
    height: 38px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #344054;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 0;
  }
.btn-add-sku:focus,
.btn-add-sku:hover {
    background: #FFFFFF;
    color: #344054;
    box-shadow: none !important;
}
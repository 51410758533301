.add-role {
    // background-color: #F5F5F5;
    background-color: #F9FAFB;
    min-height: 100vh;
    height: 100%;

    .role-cntr {
        max-width: 680px;
        margin-left: auto;
        margin-right: auto;

        .breadcrumbnav {
            padding: 16px 0;
            text-transform: uppercase;

            .breadcrumb {
                padding: 0;
                margin: 0;

                .breadcrumb-item {
                    color: #667085;
                    font: normal normal 400 12px/18px "Inter", sans-serif;
                }

                .breadcrumb-item.active {
                    color: #1D2939;
                    font-weight: 500;
                }
            }
        }

        .form {
            background-color: #FFFFFF;
            box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
            border-radius: 4px 4px 0px 0px;

            .container-fluid {
                .main-label {
                    font: normal normal 500 14px/20px "Inter", sans-serif;
                    color: #1D2939;
                }

                .radio-group {
                    display: flex;

                    .form-check {
                        margin-right: 56px;

                        input[type=radio] {
                            &:focus {
                                box-shadow: none;
                                outline: none;
                            }
                        }

                        // input:checked[type=radio] {
                        // }

                        .form-check-label {
                            font: normal normal 400 14px/20px "Inter", sans-serif;
                            color: #1D2939;
                        }
                    }
                }

                .form-control {
                    &:focus {
                        border: 1px solid #98a2b3;
                    }
                }

                .header-row {
                    .header-col {
                        padding: 20px 0 16px 0;
                        margin: 0 20px;
                        border-bottom: 1px solid #E4E7EC;

                        .form-header {
                            display: flex;
                            align-items: center;

                            .btn {
                                margin-right: 16px;
                            }
                        }
                    }
                }

                .roltype-row {
                    margin-top: 24px;

                    .roltype-col {
                        padding: 0 20px;
                    }
                }

                .rolename-row {
                    margin-top: 28px;

                    .rolename-col {
                        padding: 0 20px;
                    }
                }

                .roledesc-row {
                    margin-top: 28px;

                    .roledesc-col {
                        padding: 0 20px;

                        #roledesc {
                            min-height: 80px;
                        }
                    }
                }

                .data-res-row {
                    margin-top: 28px;

                    .data-res-col {
                        padding: 0 20px;
                    }
                }

                .nextbtn-row {
                    margin-top: 40px;

                    // margin-bottom: 135px;
                    .nextbtn-col {
                        padding: 0 20px;
                        display: flex;
                        justify-content: flex-end;

                        .btn {
                            margin-bottom: 135px;
                        }
                    }
                }
            }
        }
    }
}
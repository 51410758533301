.delete-modal {
  .modal-dialog{
    .modal-content{
      .modal-header{
        .alert-icon.delete-icon {
          background: #FEF3F2;
          border: 8px solid #FFFBFA;
        }
      }
      .modal-body{
        .footer-buttons {
          .submitbtn.delete-btn {
            background: #F04438 !important;
            border: 1px solid #F04438;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          }
        }
      }
    }
  }
}

.OuterDiv {
  color: #667085;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin: 10vh auto;
  padding: 20px;
  border: 1px solid #e4e7ec;
  height: auto;
  width: 85%;
  .heading {
    color: #000;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}

.agreementFooter {
  font-family: "Inter";
  font-style: normal;
  margin: auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1d2939;
}

.agreementAccept {
  position: fixed;
  width: 92%;
  bottom: 20;
}

.pageTitle {
  font-family: "Inter";
  border-bottom: #1d2939 0.25px solid;
  font-style: normal;
  margin: 25px 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 40px;
  color: #1d2939;
}

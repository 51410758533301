.info-sidebar {
    max-width: 360px;

    .offcanvas-header {
        font: normal normal 500 18px/28px "Inter", sans-serif;
        color: #1D2939;
        padding: 18px 20px;
        border-bottom: 1px solid #E4E7EC;

        .btn-close {

            &:focus,
            &:hover {
                box-shadow: none !important;
            }
        }
    }

    .offcanvas-body {
        padding: 24px 20px;

        .info-wraper {
            display: flex;

            .file-pic {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 72px;
                height: 72px;
                background-color: #F9FAFB;
                border: 1px solid #E4E7EC;
                border-radius: 4px;
                margin-right: 16px;
            }

            .info {
                .filename {
                    font: normal normal 500 14px/20px "Inter", sans-serif;
                    color: #1D2939;
                    margin-bottom: 6px;
                }

                .filesize {
                    font: normal normal 400 13px/19px "Inter", sans-serif;
                    color: #344054;
                    margin-bottom: 6px;
                }

                .updated-info {
                    font: normal normal 400 12px/18px "Inter", sans-serif;
                    color: #667085;
                    display: flex;
                    align-items: flex-start;
                    
                    img {
                        margin-right: 4px;
                        margin-top: 3px;
                    }
                }
            }
        }

        .download-btn {
            padding: 8px 16px;
            margin-top: 24px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            background-color: #D1E9FF;
            border: 1px solid #53B1FD;
            border-radius: 20px;
            color: #1D2939;
            font: normal normal 500 13px/19px "Inter", sans-serif;

            img {
                margin-right: 8px;
            }

            &:focus {
                box-shadow: none;
            }
            &:hover {
                background-color: #B2DDFF;
            }
        }

        .note-heding {
            font: normal normal 500 14px/20px "Inter", sans-serif;
            color: #1D2939;
            margin: 20px 0 12px 0;
        }

        .note-content {
            font: normal normal 400 13px/19px "Inter", sans-serif;
            color: #475467;
        }
    }
}
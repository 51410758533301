.addteam-member {
  .modal-dialog {
    max-width: 700px;

    .modal-content {
      border-radius: 4px;

      .modal-header {
        padding: 18px 20px;
      }

      .modal-body {
        padding: 24px 20px;
      }

      .modal-footer {
        padding: 16px 20px 12px 20px;
      }
    }
  }
}
.partner-cover-img {
  padding: 16px 24px 0 24px;

  .cover-img {
    width: 100%;
    height: 110px;
    background-image: url("../../../public/images/Rectangle-1879.png");
    background-size: cover;
    background-repeat: no-repeat;
    border-top: 1px solid #f2f4f7;
    border-right: 1px solid #f2f4f7;
    border-left: 1px solid #f2f4f7;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}

.partner-profile {
  .row-1 {
    padding: 0 24px;

    .col-profile {
      padding: 16px 24px;
      border-left: 1px solid #f2f4f7;
      border-right: 2px solid #f2f4f7;

      .profile-pic {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-top: -80px;
        padding: 0;
        border: 4px solid #ffffff;
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
          0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      }

      .name-status {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 20px;
        text-transform: capitalize;
      }

      .focus-cat {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        // background: #F9FAFB;
        border-radius: 2px;

        .cat {
          display: flex;
          align-items: center;
          background: #f9fafb;
          padding: 7px 10px;
          margin-bottom: 6px;
          margin-right: 6px;
          text-transform: capitalize;
        }
      }
    }

    .col-basicinfo {
      padding: 39px 24px;
      border-right: 1px solid #f2f4f7;

      td {
        padding-bottom: 10px;
      }

      .cln {
        padding-left: 6px;
        padding-right: 6px;
      }
    }

    .col-services {
      padding: 32px 24px 12px 24px;
      background: #f1f2f7;

      .service-cntr {
        display: flex;
        flex-wrap: wrap;

        .service-card {
          max-width: 210px;
          margin-right: 24px;
          margin-bottom: 24px;
          padding: 12px;
          background-color: #ffffff;
          box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
            0px 1px 2px rgba(16, 24, 40, 0.06);
          border-radius: 2px;
        }

        .country {
          padding: 8px;
          border: 1px solid #f2f4f7;
          border-radius: 2px;
          margin-bottom: 10px;
          text-transform: capitalize;
        }

        .service {
          padding: 11px;
          border: 1px solid #f2f4f7;
          border-radius: 2px;

          .item {
            display: flex;
            margin-bottom: 12px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

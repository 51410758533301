.brandpartnerapproval {
  .modal-dialog {
      max-width: 380px;
      .modal-content {
          border-radius: 0;
          .modal-header{
              padding: 18px 20px;
          }
          .modal-body{
              padding: 18px 20px;
              form {
                  .subtitle-row {
                      margin-bottom: 17px;
                  }
                  .checkbox-row {
                      margin-bottom: 8px;
                      .col {
                          display: flex;
                          input[type=checkbox] {
                              width: 16px;
                              height: 16px;
                              margin-right: 8px;
                          }
                      }
                  }
              }
          }
          .modal-footer{
              padding: 14px 20px;
          }
      }
  }
}
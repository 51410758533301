.todo-wraper {
    padding: 16px 16px 0 16px;

    .list-card {
        display: flex;
        border: 1px solid #F2F4F7;
        border-radius: 4px;
        background: #FCFCFD;
        box-sizing: border-box;
        margin-bottom: 16px;

        .status-complete {
            width: 7px;
            background: #32D583;
            border-radius: 3px 0px 0px 3px;
        }

        .status-pending {
            width: 7px;
            background: #FDB022;
            border-radius: 3px 0px 0px 3px;
        }

        .content {
            padding: 9px 0 9px 16px;
            flex-grow: 1;
        }

        .arrow {
            padding-right: 18px;
            align-self: center;
        }
    }
}
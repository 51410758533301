.assign-user {
    background-color: #F9FAFB;
    // background-color: #F5F5F5;
    min-height: 100vh;
    height: 100%;

    .assign-team-cntr {
        max-width: 880px;
        margin-left: auto;
        margin-right: auto;

        .breadcrumbnav {
            padding: 16px 0;
            text-transform: uppercase;

            .breadcrumb {
                padding: 0;
                margin: 0;

                .breadcrumb-item {
                    color: #667085;
                    font: normal normal 400 12px/18px "Inter", sans-serif;
                }

                .breadcrumb-item.active {
                    color: #1D2939;
                    font-weight: 500;
                }
            }
        }

        .content-wraper {
            background-color: #FFFFFF;
            box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
                0px 2px 4px -2px rgba(16, 24, 40, 0.06);
            border-radius: 4px 4px 0px 0px;

            .header-wraper {
                padding: 20px 0 16px 0;
                margin: 0 20px;
                border-bottom: 1px solid #E4E7EC;

                div {
                    display: flex;
                    align-items: center;

                    .btn {
                        margin-right: 16px;
                    }
                }
            }

            .form {
                display: flex;
                border-bottom: 1px solid #E4E7EC;
                padding: 25px 20px 23px 20px;

                .role,
                .team,
                .markets {
                    margin-right: 16px;
                }

                .role,
                .team {
                    max-width: 184px;
                    width: 100%;

                    .form-select {
                        max-height: 41px;
                        height: 100%;
                    }
                }

                .markets {
                    max-width: 384px;
                    width: 100%;
                }

                .plus-btn.btn41 {
                    width: 41px;
                    height: 41px;
                    margin-top: 30px;
                }
            }

            .container-fluid {
                .row-1 {
                    .search-col {
                        padding: 16px 20px 27px 20px;

                        .input-group {
                            width: 100%;
                            background: #F9FAFB;
                            border-radius: 2px;

                            .input-group-text,
                            .form-control {
                                background-color: #F9FAFB;
                            }
                        }
                    }
                }

                .row-2 {

                    .basicinfo-col {
                        padding-left: 20px;
                        display: flex;
                        align-items: center;

                        .basicinfo {
                            display: flex;

                            .first-letter {
                                font: normal normal 500 16px/24px "Inter", sans-serif;
                                background-color: #D1FADF;
                                width: 48px;
                                height: 48px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                text-transform: uppercase;
                                border-radius: 50%;
                                margin-right: 12px;
                            }
                        }
                    }

                    .markets-col {
                        display: flex;
                        align-items: center;

                        .markets-wraper {
                            display: flex;
                            flex-wrap: wrap;

                            .market {
                                margin-right: 8px;
                                margin-bottom: 10px;
                            }
                        }
                    }

                    .trash-col {
                        padding-right: 20px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }

                    .sep-line {
                        padding: 16px 20px;

                        div {
                            width: 100%;
                            border: 1px solid #E4E7EC;
                        }
                    }
                }
            }
        }
    }
}
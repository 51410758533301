.folder-container {
    display: flex;
    flex-direction: column;
    min-width: 193px;
    max-width: 193px;
    margin-right: 20px;
    cursor: pointer;

    .folder {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F9FAFB;
        border: 1px solid #E4E7EC;
        border-radius: 4px;
        height: 193px;

        &:hover {
            background-color: #F2F4F7;
        }
    }

    .folder-name {
        margin-top: 12px;
    }
}
.deleteButton {
  margin-right: auto;
}

.deleteButton:hover {
  background-color: rgb(201, 0, 0);
}

.primaryButton:hover {
  background-color: rgb(46, 46, 58);
}

.countrycode {
  max-width: 25%;
}

.tableDimensions {
  height: 79vh;
  width: 100%;
}

.table-link {
  cursor: pointer;
  border: none;
  color: blue;
  font-size: 13px;
}

// .MuiDataGrid-root .MuiDataGrid-cell {
//   white-space: normal !important;
//   word-wrap: break-word !important;
// }

.servicesList {
  .servicesListItemGreen {
    color: #2bdb0f;
  }
}
.field-popover {
  border: 1px solid #E4E7EC;
  border-radius: 2px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);

  .popover-body {
    padding: 12px;
    background: #FFFFFF;

    ul {
      padding: 0;
      padding-left: 1rem;
    }
  }
}
.MuiTablePagination-displayedRows {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.tablemui-custom {
  border: 1px solid #E4E7EC !important;
  .MuiDataGrid-main {
    .MuiDataGrid-columnHeaders {
      background: #F2F4F7;
      font: normal normal 500 13px/19px "Inter", sans-serif;
      color: #101828;
      .MuiDataGrid-columnHeadersInner {
        .MuiDataGrid-columnHeader {
          border-right: 1px solid #E4E7EC;
          padding: 0 24px;
          &:last-child {
            border-right: 0;
          }
          .MuiDataGrid-columnSeparator {
            display: none;
          }
        }
      }
    }
    .MuiDataGrid-virtualScroller {
      font: normal normal 400 13px/19px "Inter", sans-serif !important;
      color:  #101828;
      .MuiDataGrid-virtualScrollerContent {
        .MuiDataGrid-virtualScrollerRenderZone {
          .MuiDataGrid-row {
            &:hover {
              background-color: "#F9FAFB";
            }
            .MuiDataGrid-cell {
              padding: 0 24px;
            }
          }
        }
      }
      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #eaecf0;
        border-radius: 8px;
      }
    }
  }
}
.MuiDataGrid-menu {
  .MuiPaper-root {
    border: 1px solid #E4E7EC;
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);
      border-radius: 4px;
    .MuiList-root {
      padding: 0;
      .MuiButtonBase-root {
        font: normal normal 400 12px/18px "Inter", sans-serif;
        color: #101828;
        padding: 8px 12px;
        &:hover {
          background-color: #F9FAFB;
        }
      }
    }
  }
}
.primaryButton:hover {
  background-color: rgb(46, 46, 58);
}

.footer {
  border: none;
}

.file-card {
  padding: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px dashed #cbd5e0;
  background-color: #edf2f7;
  min-width: 380px;
  min-height: 200px;
  &:hover {
    background-color: #deebf8;
    cursor: pointer;
  }

  .file-inputs {
    position: relative;
    margin-bottom: 1rem;

    input {
      position: relative;
      text-align: right;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
      height: 46px;
      max-width: 200px;
    }
    .file-upload-img {
      position: relative;
      text-align: center;
      z-index: 20;
      cursor: pointer;
      height: 46px;
      max-width: 200px;
      img {
        height: 30px;
        z-index: 24;
      }
    }
  }
}

.file-card2 {
  padding: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #989898;
  min-width: 380px;
  min-height: 200px;
  .file-inputs {
    position: relative;
    margin-bottom: 1rem;

    input {
      position: relative;
      text-align: right;
      opacity: 1;
      z-index: 2;
      height: 46px;
      max-width: 200px;
    }
    .file-upload-img {
      position: relative;
      text-align: center;
      z-index: 20;
      height: 46px;
      max-width: 200px;
      img {
        height: 30px;
        z-index: 24;
      }
    }
  }
}

.main {
  font-weight: bold;
  margin-bottom: 0.4em;
}

.info {
  font-size: 0.8rem;
}

.file-item {
  list-style: none;
  margin: 1.2em 0;
  background-color: #edf2f7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  svg {
    color: #f70000;
    &:first-child {
      font-size: 1em;
      margin-right: 0.8em;
    }
  }

  p {
    flex: 1;
    margin-top: 1em;
    font-size: 0.9rem;
  }

  .actions {
    justify-self: flex-end;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

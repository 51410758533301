.modal700 {
    .modal-dialog {
        max-width: 700px;

        .modal-content {
            border-radius: 0;
            background: #FFFFFF;

            .modal-header {
                border: 0;
                padding: 18px 20px;
                border-bottom: 1px solid #E4E7EC;

                .btn-close {

                    &:focus,
                    &:hover {
                        box-shadow: none !important;
                    }
                }
            }

            .modal-body {
                // padding: 24px 20px 0 20px;
                padding: 0;

                .title-text {
                    margin-bottom: 8px;
                }

                .form {
                    .container-fluid {
                        .row-body {
                            padding: 0 20px;

                            .multiselect-container {
                                .search-wrapper {
                                    border-radius: 0;
                                }
                            }
                        }

                        .btn-footer {
                            display: flex;
                            justify-content: flex-end;
                            padding: 16px 20px 12px 20px;

                            :first-child {
                                margin-right: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.login-container {
  height: 100vh;
  .row {
    height: 100%;
    .left-content {
      padding: 0;
      background-image: url("../../../public/svgs/map.svg"),
        linear-gradient(
          236.47deg,
          #2589c1 -21.46%,
          #2fa2e7 -6.73%,
          #0a2d4f 69.45%,
          #082648 99.89%
        );
      .container-fluid {
        height: 100%;
        .row {
          .col-1 {
            padding: 24px 0 0 24px;
          }
          .col-2 {
            padding-left: 60px;
            align-self: center;
            .unlockyour {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
              line-height: 32px;
              letter-spacing: 0.05em;
              text-transform: uppercase;
              color: #ffffff;
              margin-bottom: 4px;
              span {
                font-weight: 400;
              }
            }
            .growth-journey {
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 36px;
              line-height: 44px;
              letter-spacing: -0.02em;
              color: #ffffff;
              margin-bottom: 32px;
            }
            .text-signup {
              margin-bottom: 8px;
            }
            .signup-btn {
              height: 38px;
              max-width: 140px;
              width: 100%;
              margin-bottom: 20px;
              font-family: "Inter", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: #ffffff;
              background: rgba(255, 255, 255, 0.5);
              border: 0;
              box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.5);
              backdrop-filter: blur(25px);
              border-radius: 2px;
              &:focus,
              &:hover {
                background: rgba(255, 255, 255, 0.5);
                color: #ffffff;
                box-shadow: none !important;
                border: 0;
              }
            }
            .btn-mr {
              margin-right: 8px;
            }
            .learnmore {
              a:hover {
                color: #e4e7ec;
              }
            }
          }
          .col-3 {
            padding: 0 0 24px 24px;
            align-self: flex-end;
            div {
              display: flex;
              img {
                margin-left: 16px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .right-content {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .loginform {
        max-width: 320px;
        width: 100%;
      }
      .tradehublogo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 41px;
        .logo {
          margin-bottom: 11px;
        }
        .iTradeHub-text {
          color: #1f2937;
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
        }
      }
      .label-email {
        margin: 24px 0 6px 0;
      }
      .label-password {
        margin-bottom: 6px;
      }
      .email-input {
        border-radius: 0;
        height: 40px;
        max-width: 320px;
        width: 100%;
      }
      .email-input:focus,
      .email-input:active {
        outline: none;
        box-shadow: none;
      }
      .password-input {
        border-radius: 0;
        height: 40px;
      }
      .password-input:focus,
      .password-input:active {
        outline: none;
        box-shadow: none;
      }
      .forgot-password {
        align-self: flex-end;
        text-decoration: none;
        color: #1570ef;
        margin-top: 6px;
      }
      .forgotpassword-wraper {
        display: flex;
        justify-content: flex-end;
        .forgotpassword-btn {
          padding: 0;
          margin-top: 6px;
          background-color: #ffffff;
          color: #1570EF;
          border: 0;
          &:focus {
            box-shadow: none;
          }
        }
      }
      .login-btn {
        height: 38px;
        border-radius: 0;
        background: #0152fb;
        border: 1px solid #0152fb;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        width: 100%;
        margin-top: 32px;
      }
      .opt-setpassword-wraper {
        width: 320px;
      }
      .otp-varification {
        //     display: flex;
        //     .otp-input {
        //         border: 0;
        //         box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        //         border: 1px solid #D0D5DD;
        //         width: 64px;
        //         height: 64px;
        //         text-align: center;
        //         font-size: 48px;
        //         border-radius: 8px;
        //         margin-right: 10px;
        //     }
        //     .otp-input:focus {
        //         outline: none;
        //     }
        .otp-btns {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .resendotp-btn {
            width: 149px;
            height: 38px;
            background: #ffffff;
            border: 1px solid #d0d5dd;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 4px;
          }
          .verifyemail-btn {
            width: 149px;
            height: 38px;
            background: #344054;
            color: #ffffff;
            border: 1px solid #344054;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 4px;
          }
        }
      }
    }
  }
}

.form-text span {
  color: red;
}

.servicecpbts {
    .container-1 {
        .form {
            .row-input {
                .col-market {
                    padding: 12px;
                }
                .col-services {
                    padding: 21px 18px 21px 16px;
                }
                .col-action {
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .row-output {
                .col-market {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 0 12px;
                }
                .col-services {
                    padding: 21px 18px 21px 16px;
                }
                .col-action {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                }
            }
        }
    }
}
.breadcrumb-nav {
    padding: 0 24px;

    .breadcrumb {
        margin: 0;
        padding-top: 16px;
        text-transform: uppercase;

        .breadcrumb-item {
            font: normal normal 400 12px/18px "Inter", sans-serif;
        }

        .breadcrumb-item.active {
            font: normal normal 500 12px/18px "Inter", sans-serif;
            color: #1D2939;
        }
    }
}
.plus-btn-blue {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    background: #FFFFFF;
    color: #0152FB;
    border-radius: 0;
    border: none;

    font: normal normal 400 13px/19px "Inter", sans-serif;

    img {
        margin-right: 7px;
    }

    &:active,
    &:focus,
    &.active,
    &:hover,
    &:active:focus {
        background: #FFFFFF;
        color: #0152FB;
        background-image: none;
        outline: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

}
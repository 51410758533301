.feed-wraper {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
  //height: 32vh;

  .feedtitle {
    border-bottom: 1px solid #e4e7ec;
    padding-bottom: 16px;
    margin-bottom: 8px;
  }

  .markasread {
    display: flex;
    justify-content: flex-end;

    .btn-masr {
      border: 0;
      background: #ffffff;
      padding: 0;
    }
  }
  .activity-table {
    tr {
      border-bottom: 1px solid #e4e7ec;
      td {
        position: relative;
        .read-status {
          width: 8px;
          height: 8px;
          background: #F04438;
          position: absolute;
          left: 10px;
          top: 10px;
          border-radius: 50px;
        }
      }
    }
  }
}

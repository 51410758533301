.resource-wraper {
    display: flex;
    flex-direction: column;
    padding: 16px 0 0 16px;
    .rsc-title {
        margin-bottom: 14px;
    }
    .table-resource {
        border-collapse: separate;
        border-spacing: 0 10px;
        .td-img {
            // width: 48px;
            // height: 48px;
            vertical-align: top;
            text-align: center;
            .img-d {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 48px;
                height: 48px;
                background: #F9FAFB;
            }
        }
    }
}
.detailsdisplay-modal {
  .modal-dialog {
    max-width: 400px;
    .modal-content {
      background-color: #f9fafb;
      border-radius: 0;
      .modal-header {
        .modal-title {
          text-transform: capitalize;
        }
        background-color: #ffffff;
        .status-badge {
          display: flex;
          margin-left: 8px;
          padding: 2px 8px;
          background: #d1e9ff;
        }
      }
      .modal-body {
        .spacing-row {
          padding-top: 8px;
          padding-bottom: 8px;
          background-color: #ffffff;
        }
        .value-space {
          padding: 0 6px;
        }
        .socialmedia-row {
          align-items: baseline;
          margin-bottom: 8px;
          .col {
            flex-grow: 0;
          }
        }
        .category-row {
          margin-bottom: 8px;
        }
        .prefmodel-row {
          margin-bottom: 8px;
        }
        .prefservice-row, .cat-row, .files-row {
          align-items: center;
          padding: 0;
          margin-bottom: 8px;
          .prefservice-col, .cat-col {
            display: flex;
            flex-wrap: wrap;
            div {
              border: 1px solid #e4e7ec;
              padding: 2px 8px;
              margin-right: 6px;
              margin-bottom: 6px;
            }
          }
        }
        .files-row {
          .file {
            color: #0152FB;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .documents-row {
          background-color: #ffffff;
          img {
            margin-right: 8px;
          }
        }
      }
    }
  }
}

.meter-wraper {
    display: flex;
    align-items: baseline;
    .strength-meter {
        position: relative;
        height: 8px;
        width: 100%;
        margin-top: 11px;
        margin-right: 8px;
        background: #F9FAFB;
        border-radius: 6px;
        overflow: hidden;
    }

    .strength-meter::before {
        // --strength: 10;
        // --progresscolor: #DC6803;
        content: '';
        position: absolute;
        left: 0;
        height: 100%;
        width: calc(1% * var(--strength, 0));
        border-radius: 6px;
        // background-color: #DC6803;
        background: var(--progresscolor);
        transition: width 200ms;
    }
    .status {
        color: var(--statusColor,#F97066);
    }
}
.file-access {
    // background-color: #F5F5F5;
    background-color: #F9FAFB;
    min-height: 100vh;
    height: 100%;

    .fileaccess-cntr {
        max-width: 680px;
        margin-left: auto;
        margin-right: auto;

        .breadcrumbnav {
            padding: 16px 0;
            text-transform: uppercase;

            .breadcrumb {
                padding: 0;
                margin: 0;

                .breadcrumb-item {
                    color: #667085;
                    font: normal normal 400 12px/18px "Inter", sans-serif;
                }

                .breadcrumb-item.active {
                    color: #1D2939;
                    font-weight: 500;
                }
            }
        }

        .form {
            background-color: #FFFFFF;
            box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
            border-radius: 4px 4px 0px 0px;

            .container-fluid {

                .header-row {
                    .header-col {
                        padding: 20px 0 16px 0;
                        margin: 0 20px;
                        border-bottom: 1px solid #E4E7EC;

                        .form-header {
                            display: flex;
                            align-items: center;

                            .btn {
                                margin-right: 16px;
                            }
                        }
                    }
                }

                .input-row {
                    margin-top: 24px;

                    .col {
                        display: flex;
                        padding: 0 20px;

                        .entity {
                            max-width: 184px;
                            width: 100%;
                            margin-right: 16px;

                            .form-select {
                                height: 41px;
                            }
                        }

                        .folders {
                            max-width: 388px;
                            width: 100%;
                            margin-right: 12px;
                        }

                        .plus-btn.btn41 {
                            width: 41px;
                            height: 41px;
                            margin-top: 30px;
                        }
                    }
                }

                .br-row {
                    .sep-line {
                        padding: 23px 20px 16px 20px;

                        div {
                            width: 100%;
                            border: 1px solid #E4E7EC;
                        }
                    }
                }

                .display-row {
                    .display-col {
                        padding: 0 20px;

                        .display-wraper {
                            border: 1px solid #E4E7EC;
                            border-radius: 4px;

                            .display-header {
                                padding: 12px;
                                display: flex;
                                justify-content: space-between;
                            }

                            .folders {
                                display: flex;
                                flex-wrap: wrap;
                                padding: 0 12px 12px 12px;

                                div {
                                    margin-right: 8px;
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }
                }

                .nextbtn-row {
                    margin-top: 40px;

                    .nextbtn-col {
                        padding: 0 20px;
                        display: flex;
                        justify-content: flex-end;

                        .btn {
                            margin-bottom: 135px;
                        }
                    }
                }
            }
        }
    }
}
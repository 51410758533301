.carsel {
    // background-image: url("../../../../public/svgs/map.svg");
    border-radius: 6px;
    .carousel-item {
        border-radius: 6px;
        height: calc((100vh - 97px) / 2);
        background: linear-gradient(
            236.47deg, 
            #2589C1 -21.46%,
            #2FA2E7 -6.73%,
            #0A2D4F 69.45%,
            #082648 99.89%);
            .carousel-caption {
                top: 15%;
                left: 5%;
                text-align: left;
                // max-width: 300px;
                right: auto;
                padding:5px;
                div {
                    margin-bottom: 6px;
                }
            }
    }
}
.centered-layout {
    .row {
        .content-section {
            padding: 0;

            .content-wraper {
                display: flex;
                justify-content: center;
                background-color: #F9FAFB;
                min-height: 100vh;
                height: 100%;

                .content {
                    max-width: 680px;
                    width: 100%;

                    .breadcrumbnav {
                        text-transform: uppercase;

                        .breadcrumb {
                            margin: 0;
                            padding: 16px 0;
                        }

                        @media screen and (max-width: 600px) {
                            .breadcrumb {
                                min-width: 300px;
                            }
                        }
                    }

                    .headerbody-cntr {
                        box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
                            0px 2px 4px -2px rgba(16, 24, 40, 0.06);
                        border-radius: 4px 4px 0px 0px;

                        .content-header {
                            display: flex;
                            align-items: center;
                            background-color: #FFFFFF;
                            padding: 20px 20px 16px 20px;
                        }

                        .hr-line {
                            padding: 0 20px 0 20px;
                            background-color: #FFFFFF;

                            div {
                                border-top: 1px solid #E4E7EC;
                            }
                        }

                        .content-body {
                            background-color: #FFFFFF;
                        }
                    }
                }
            }
        }
    }
}
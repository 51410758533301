.productlist {
  .row {
    .productlist-content {
      .breadcrumb-nav {
        padding: 0 24px;
        .breadcrumb {
          margin: 0;
          padding-top: 16px;
        }
      }
      .action-panel {
        display: flex;
        padding: 16px 24px;
        .addproduct {
          margin-left: auto;
        }
      }
      .producttable {
        padding: 0 24px;
        margin-top: 16px;
      }
    }
  }
}

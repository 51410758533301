.switch {
    .form-check-input {
        width: 36px;
        height: 20px;
        margin-top: 0;
        margin-bottom: 0;
        margin-right: 12px;
        &:focus {
            box-shadow: none;
        }
    }
}
.grey-text {
  color: gray;
  font-weight: 400;
  font-size: 15px;
}

.formCheckbox {
  font-size: 16px;
  margin: 0.5rem;
  label {
    display: block;
    padding-left: 15px;
    text-indent: -15px;
  }

  input {
    width: 15px;
    height: 15px;
    padding: 0;
    margin: 0 10px 0 0;
    vertical-align: bottom;
    position: relative;
    top: -1px;
  }
}

.modalWidth {
  max-width: 80%;
  margin: 2rem auto;
}

.file-cntr {
    border-radius: 4px;
    max-width: 193px;
    width: 100%;
    margin-right: 20px;
    margin-bottom: 24px;
    font: normal normal 400 14px/20px "Inter", sans-serif;

    .fhead {
        border: 1px solid #E4E7EC;
        border-radius: 4px;

        .img-cntr {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #F2F4F7;
            height: 165px;
            position: relative;

            .form-check {
                position: absolute;
                top: 14px;
                left: 14px;

                .form-check-input {
                    height: 16px;
                    width: 16px;
                    border: 1px solid #D0D5DD;

                    &:focus {
                        box-shadow: none;
                    }
                }
            }

            .download-wraper {
                .download-btn {
                    display: none;
                    padding: 11.2px;
                    margin: 0;
                    border: 1px solid #1570EF;
                    border-radius: 50px;
                }

                p {
                    display: none;
                    margin-bottom: 0;
                    margin-top: 8px;
                    color: #FFFFFF;
                    font: normal normal 400 12px/18px "Inter", sans-serif;
                }
            }

            &:hover {
                .file-pic {
                    display: none;
                }

                .download-wraper {
                    display: block;

                    .download-btn,
                    p {
                        display: block;
                    }
                }

                background-color: #101828;
                opacity: 0.6;
                border-radius: 4px;
            }
        }
    }

    .filename {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #1D2939;
        margin-top: 13px;
    }

    .filesize {
        color: #667085;
        margin-top: 4px;
    }
}
.aeginfocards {
    background: #F5F5F5;
    .row {
        padding: 24px 10px 0 24px;
        .col-brands {
            padding-left: 0;
            padding-bottom: 12px;
            .card-brands {
                padding: 25px 0 16px 16px;
                background-image: url("../../../../public/svgs/brand-card.svg"),
                linear-gradient(269.94deg, #D1E9FF 0.05%, #FFFFFF 99.95%);
                background-repeat: no-repeat;
                background-size: cover;
                border: 1px solid #E3F2FF;
                border-radius: 4px;
            }
        }
        .col-partners {
            padding-left: 0;
            padding-bottom: 12px;
            .card-partners {
                padding: 25px 0 16px 16px;
                background-image: url("../../../../public/svgs/market-card.svg"),
                linear-gradient(269.94deg, #FFC6D3 0.05%, #FFFFFF 99.95%);
                background-repeat: no-repeat;
                background-size: cover;
                border: 1px solid #FFDDE5;
                border-radius: 4px;
            }
        }
        .col-discovery {
            padding-left: 0;
            padding-bottom: 12px;
            .card-discovery {
                padding: 25px 0 16px 16px;
                background-image: url("../../../../public/svgs/partner-card.svg"),
                linear-gradient(269.94deg, #DFCCFF 0.05%, #FFFFFF 99.95%);
                background-repeat: no-repeat;
                background-size: cover;
                border: 1px solid #ECE1FF;
                border-radius: 4px;
            }
        }
        .col-reports {
            padding-left: 0;
            padding-bottom: 12px;
            .card-reports {
                padding: 25px 0 16px 16px;
                background-image: url("../../../../public/svgs/trades-card.svg"),
                linear-gradient(269.94deg, #B3FFEB 0.05%, #FFFFFF 99.95%);
                background-repeat: no-repeat;
                background-size: cover;
                border: 1px solid #D2FFF4;
                border-radius: 4px;
            }
        }
    }
}
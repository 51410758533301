.confirm-modal {
    .modal-dialog {
        max-width: 400px;

        .modal-content {
            border-radius: 4px;
            background: #FFFFFF;

            .modal-header {
                border: 0;
                padding: 24px 24px 20px 24px;

                .alert-triangle {
                    padding: 13px;
                    background: #FFFAEB;
                    border-radius: 50px;
                    border: 8px solid #FFFCF5;
                }
            }

            .modal-body {
                padding: 0 24px 32px 24px;

                .title-text {
                    margin-bottom: 8px;
                }
            }

            .modal-footer {
                justify-content: space-between;
                border: 0;
                padding: 0 24px 24px 24px;
                flex-wrap: nowrap;

                .submitbtn {
                    height: 38px;
                    max-width: 168px;
                    margin: 0;
                    width: 100%;
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #ffffff;
                    background: #0152fb;
                    border: 1px solid #0152fb;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    border-radius: 0;

                    &:focus,
                    &:hover {
                        box-shadow: none !important;
                    }
                }

                .cancelbtn {
                    height: 38px;
                    max-width: 168px;
                    width: 100%;
                    margin: 0;
                    font-family: "Inter";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #344054;
                    background: #FFFFFF;
                    border: 1px solid #D0D5DD;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                    border-radius: 0;

                    &:focus,
                    &:hover {
                        background: #FFFFFF;
                        color: #344054;
                        box-shadow: none !important;
                        border: 1px solid #D0D5DD;
                    }
                }
            }
        }
    }
}
.header-1 {
  padding: 0 24px;
  height: 49px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4e7ec;
  background: #ffffff;

  .icons {
    margin-left: auto;

    .notification-bell {
      margin-left: 12px;
      margin-right: 12px;
    }

    .account-dropdown {
      #dropdown-basic {
        padding: 0;
        background-color: #ffffff;
        border: 0;

        &:focus,
        &:hover {
          box-shadow: none !important;
        }
      }

      ::before {
        display: none;
      }

      ::after {
        display: none;
      }

      .dropdown-menu {
        border: 1px solid #e4e7ec;
        border-radius: 4px;
        padding: 0;
        box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
          0px 2px 4px -2px rgba(16, 24, 40, 0.06);

        .basic-info {
          border-bottom: 1px solid #e4e7ec;
          padding-top: 12px;
          cursor: default;
          .basic-info-wraper {
            display: flex;
            .short-name {
              width: 31px;
              height: 32px;
              border-radius: 50px;
              background-color: #d1e9ff;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 12px;
              margin-bottom: 12px;
            }
          }
          &:focus {
            background-color: #ffffff;
          }
          &:hover {
            background-color: #f9fafb;
          }
        }
        .usertype {
          border-bottom: 1px solid #e4e7ec;
          padding-top: 8px;
          padding-bottom: 8px;
          cursor: default;
          .usertype-wraper {
            display: flex;
            align-items: baseline;
            .usertype-icon {
              margin-right: 8px;
            }
          }
          &:focus {
            background-color: #ffffff;
          }
          &:hover {
            background-color: #f9fafb;
          }
        }
        .logout {
          padding-top: 12px;
          padding-bottom: 12px;
          &:focus {
            background-color: #ffffff;
          }
          &:hover {
            color: #d92d20;
            background-color: #f9fafb;
          }
        }
      }
    }
  }
}

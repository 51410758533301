.header-1 {
  padding: 0 24px;
  height: 49px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e4e7ec;
  .icons {
    margin-left: auto;
    .notification-bell {
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}

.headerBar {
  height: 100%;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.headerPlusIcon {
  margin-right: 10px;
}

.adminusersTabs {
  height: 36px;
  background: #ffffff;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tabContainer {
  padding: 0;
  overflow-x: hidden;
}
.tabHeader {
  display: flex;
  height: 56px;
}
.column1 {
  display: flex;
  flex-grow: 1;
  width: 90%;
  padding: 10px 20px;
}

.teritiary34btn {
  margin-left: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.column2 {
  margin: 10px 40px 10px 10px;
  align-self: center;
  flex-grow: 0;
}
.colfilterBtn {
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 6px;
  height: 36px;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 2rem;
  line-height: 30px;
  padding: 10px;
  color: #1d2939;
}

.content1 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 48px 0 0;
  overflow: hidden;
}

.content4 {
  min-width: 500px;
  width: 98%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 2.5rem;
  min-height: 100vh !important;
  z-index: 100;
  overflow: hidden;
}
